export class CapturaDemandaFields {
    channel: string;
    psp: string;
    account: string;
    ['Txn ID']: string;
    ['client-id']: string;
    pnr: string;
    ['Point of Sale']: string;
    ['Pricing Currency']: string;
    value: string;
    selected: boolean | false;
}

export class CapturePayment {
    ['capture-payment']: CapturePaymentItem[];
}

export class CapturePaymentItem {
    channel: string;
    psp: string;
    account: string;
    ['transaction-id']: string;
    ['client-id']: string;
    ['merchant-reference']: string;
    ['payment-id']: string;
    pnr: string;
    amount: Amount;
}

export class Amount {
    ['country-id']: string;
    ['currency-id']: string;
    value: string;
}

export class ResponseCapture {
    response: ResponseCaptureItem[];
}

export class ResponseCaptureItem  {
    message: string;
    ['status-code']: string;
    created: string;
    pnr: string;
    ['transaction-id']: string;
}
