import { Component, OnInit, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MdbTablePaginationComponent, MdbTableDirective } from 'angular-bootstrap-md';
import { CapturaDemandaFields, CapturePayment, CapturePaymentItem, Amount, ResponseCapture, ResponseCaptureItem } from '../../shared/captura-demanda';
import { RelanzamientosService } from '../../services/relanzamientos.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import * as Papa from 'papaparse';
import { isNull } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-captura-demanda',
  templateUrl: './captura-demanda.component.html',
  styleUrls: ['./captura-demanda.component.css']
})
export class CapturaDemandaComponent implements OnInit {
  @ViewChild('mdbTablePagination', { static: true })  mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild('mdbTablePaginationResult', { static: true })  mdbTablePaginationResult: MdbTablePaginationComponent;
  @ViewChild('tableEl', { static: true }) tableEl: MdbTableDirective;
  @ViewChild('tableE2', { static: true }) tableE2: MdbTableDirective;
  @ViewChild('form') form;
  @Output() newItemEvent = new EventEmitter<CapturePayment>();

  public fileField: File[];
  public capturaFileList : CapturaDemandaFields[] = [];
  public filterPnr: string = '';
  private listFilter : CapturaDemandaFields[] = [];
  public selectedPage: any;
  public selectedAllItems: any;
  private selectedAItem: boolean;
  private previous: any = [];
  private capturePayment : CapturePayment;
  public captureItemList: ResponseCaptureItem[]=[];
  private blockAllFP: boolean;

  constructor(private relanzamientosService: RelanzamientosService, private cdRef: ChangeDetectorRef, private spinnerService: NgxSpinnerService) {
    this.capturePayment = new CapturePayment();
    this.selectedAItem = false;
   }

  ngOnInit(): void {
  }

  onChange(files: File[]){
    this.capturaFileList = [];
    this.captureItemList = [];
    this.listFilter = [];
    var ext =this.validaFile(files);
    if (ext){
      if(files[0]){
        this.spinnerService.show();
        Papa.parse(files[0], {
          header: true,
          skipEmptyLines: true,
          complete: (result,file) => {
            var cabeceras = this.validaCabeceras(result.meta.fields);
            if(cabeceras){
              this.capturaFileList = result.data;
              this.listFilter = result.data;
              if((this.capturaFileList.length > 0) && (this.capturaFileList.length < 101)){
                this.configureTable();
                this.spinnerService.hide();
                this.reset();
                this.clearCheckbox();
                this.filterPnr = '';
              }else{
                this.capturaFileList = [];
                Swal.fire('El archivo no cuenta con registros o supera el máximo de cien (100) registros, favor de validar', '', 'info');
                this.spinnerService.hide();
                this.reset();
              }
            }
            else{
              Swal.fire('El archivo no cuenta con las cabeceras correspondientes, favor de validar', '', 'info');
              this.spinnerService.hide();
              this.reset();
            }
          }
        });
      }
    }
    else{
      Swal.fire('El archivo no es de tipo csv, intente con otro archivo', '', 'info');
      this.spinnerService.hide();
      this.reset();
    }
  }
  
  configureTable(): void {
    this.captureItemList = [];
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);
    this.tableEl.setDataSource(this.capturaFileList);
    this.capturaFileList = this.tableEl.getDataSource();
    this.previous = this.tableEl.getDataSource();
    
    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.mdbTablePagination.firstPage();
    this.cdRef.detectChanges();
  }

  reset() {
    this.form.nativeElement.reset()
  }

  validaFile(files: File[]){
    if (files[0].name.includes('.csv') || files[0].name.includes('.CSV')){
      return true;
    }
    else{
      return false;
    }
  }

  validaCabeceras(file:any[]){
    if (file.includes('channel') && file.includes('psp') && file.includes('account') && file.includes('Txn ID') && file.includes('client-id') && file.includes('pnr') && file.includes('Point of Sale') && file.includes('Pricing Currency') && file.includes('value') ){
      return true;
    }
    else{
      return false;
    }
  }

  fieldsChange(): void {
    if((this.filterPnr === '' || null)){
      this.filterPnr = '';
      this.capturaFileList = this.listFilter;
      this.clearCheckbox();
      this.configureTable();
    }
  }

  filterList(): void {
    this.spinnerService.show();
    this.capturaFileList = this.listFilter.filter((find) => find.pnr.toLowerCase().includes(this.filterPnr.toLowerCase()));
      if(this.capturaFileList.length === 0){
        this.filterPnr = '';
        Swal.fire('La búsqueda no arrojo resultados', 'Se muestran datos iniciales.', 'info');
        this.capturaFileList = this.listFilter;
      }
    this.clearCheckbox()
    this.configureTable();
    this.spinnerService.hide();
  }

  selectPage(itemPaginationInicial: number, itemPaginationFinal: number): void {
    if (this.selectedAllItems) {
      this.selectedAllItems = false;
      for(const item of this.capturaFileList){
        item.selected = this.selectedAllItems;
      }
    }
    for (let i = itemPaginationInicial - 1; i <= itemPaginationFinal - 1; i++) {
      this.capturaFileList[i].selected = this.selectedPage;
    }
  }
  
  selectAllItems(): void {
    this.selectedPage = false;
    for(const item of this.capturaFileList){
      item.selected = this.selectedAllItems;
    }
  }

  changeDisableSelected(event: any) {
    this.blockAllFP = event.target.checked;
  }

  checkIfAllSelected(): void {
    this.selectedPage = this.capturaFileList.every(function (item: any): boolean {
      return item.selected === true;
    });

    this.blockAllFP = this.selectedPage;
  }

  clearCheckbox(): void{
    this.selectedPage = false;
    this.blockAllFP = false;
    this.selectPage(1, this.capturaFileList.length);
  }
  
  checkIfaItemIsSelected(): boolean {
    for(const item of this.capturaFileList){
      if(item.selected){
        this.selectedAItem = true;
        return false;
      }
    }
    this.selectedAItem = false;
    return true;
  }

  getCaptureList(): CapturePaymentItem[] {
    let itemsSelectedList: CapturePaymentItem[] = []
    for(const item of this.capturaFileList){
      const itemSelected = new CapturePaymentItem();
      if(item.selected){
        itemSelected.channel = item.channel;
        itemSelected.psp = item.psp;
        itemSelected.account = item.account;
        itemSelected['transaction-id'] = item['Txn ID'];
        itemSelected['client-id'] = item['client-id'];
        itemSelected['merchant-reference'] = '';
        itemSelected['payment-id'] = '';
        itemSelected.pnr = item.pnr;
        itemSelected.amount = new Amount (); 
        itemSelected.amount['country-id'] = item['Point of Sale'];
        itemSelected.amount['currency-id'] = item['Pricing Currency'];
        itemSelected.amount.value = item.value; 
        itemsSelectedList.push(itemSelected);
      }
    }
    return itemsSelectedList;
  }

  sendCapture(): void {
    this.spinnerService.show();
    this.previous = [];
    this.capturePayment['capture-payment'] = this.getCaptureList();
    this.relanzamientosService.sendCapturePayment(this.capturePayment).subscribe(res => {
      res.response.forEach(element => {
        let captureItem: ResponseCaptureItem = new ResponseCaptureItem();
        element.error ? captureItem.message = 'Error al ejecutar proceso en el servicio' : captureItem.message = element.message;
        captureItem['status-code'] = element['status-code'];
        captureItem.created = element.created;
        captureItem.pnr = element.pnr;
        captureItem['transaction-id'] = element['transaction-id'];
        this.captureItemList.push(captureItem);
      });
      this.configureTableResult();
      this.spinnerService.hide(); 
    }, err => {
      console.log(err);
      Swal.fire('Error de comunicación', 'Por favor intente de nuevo', 'error');
      this.spinnerService.hide();
    });
  }

  configureTableResult(): void {
    this.capturaFileList = [];
    this.mdbTablePaginationResult.setMaxVisibleItemsNumberTo(10);
    this.tableE2.setDataSource(this.captureItemList);
    this.captureItemList = this.tableE2.getDataSource();
    this.previous = this.tableE2.getDataSource();
    
    this.mdbTablePaginationResult.calculateFirstItemIndex();
    this.mdbTablePaginationResult.calculateLastItemIndex();
    this.mdbTablePaginationResult.firstPage();
    this.cdRef.detectChanges();
  }

}
