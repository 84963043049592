import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './components/home/home.component';
import { RelanzamientosComponent } from './components/relanzamientos/relanzamientos.component';
import { LoginComponent } from './components/login/login.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TarjetasGenericasComponent } from './components/tarjetas-genericas/tarjetas-genericas.component';
import { DuplexComponent } from './components/duplex/duplex.component';
import { PurgadasComponent } from './components/purgadas/purgadas.component';
import { FpGenericasComponent } from './components/fp-genericas/fp-genericas.component';
import { ReportingServiceComponent} from './components/reporting-service/reporting-service.component';
import { TicketingComponent } from './components/ticketing/ticketing.component';
import { CancelacionesUatpComponent } from './components/cancelaciones-uatp/cancelaciones-uatp.component';
import { WorldpayAuthVoidComponent } from './components/worldpay-auth-void/worldpay-auth-void.component';
import { ReembolsosWorldpayComponent } from './components/reembolsos-worldpay/reembolsos-worldpay.component';
import { MediosPagoGestionComponent } from './components/medios-pago-gestion/medios-pago-gestion.component';
import { BancosPSEGestionComponent } from './components/bancos-pse-gestion/bancos-pse-gestion.component';
import { GestionModulosComponent } from './components/gestion-modulos/gestion-modulos.component';
import { HistorialLogsComponent } from './components/historial-logs/historial-logs.component';

import { environment } from '../environments/environment';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { AuthGuardGuard } from './components/guards/auth-guard.guard';
import { AuthInterceptor } from './auth-interceptor';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OwlDateTimeIntl } from 'ng-pick-datetime';
import { VisionComponent } from './components/vision/vision.component';
import { CapturaDemandaComponent } from './components/captura-demanda/captura-demanda.component';
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
const isIframe = window !== window.parent && !window.opener;

export class DefaultIntl extends OwlDateTimeIntl {
  cancelBtnLabel: 'C';
  setBtnLabel: 'S';
  rangeFromLabel: 'F';
  rangeToLabel: 'T';
}

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: 'https://login.microsoftonline.com/' + environment.tenantId,
      redirectUri: environment.redirectUrl
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('${environment.redirectUrl}', ['${environment.clientId}']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect
  };
}
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    RelanzamientosComponent,
    LoginComponent,
    TarjetasGenericasComponent,
    DuplexComponent,
    PurgadasComponent,
    FpGenericasComponent,
    ReportingServiceComponent,
    TicketingComponent,
    CancelacionesUatpComponent,
    WorldpayAuthVoidComponent,
    ReembolsosWorldpayComponent,
    MediosPagoGestionComponent,
    BancosPSEGestionComponent,
    GestionModulosComponent,
    HistorialLogsComponent,
    VisionComponent,
    CapturaDemandaComponent
  ],
  imports: [
    OAuthModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MsalModule,
    DragDropModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {provide: OwlDateTimeIntl, useClass: DefaultIntl},
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    DatePipe
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
