<app-navbar></app-navbar>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class=" container">
                
    <div class="my-3 text-app-color">
        <h5 class="card-title">CAPTURA A DEMANDA</h5>
    </div>

    <hr/>

    <form class="form-inline my-5" #form>
        <div class="form-group">
            <div class="container">

                <div class="input-group text-app-color">
                    <label class="col-form-label" for="pnr">Seleccione un archivo:</label>
                    <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                        <input type="file" class="col-form-label col-xs-auto col-sm-auto col-md-auto"  [(ngModel)]="fileField" (change)="onChange(uploads.files)" name="files" #uploads/>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <hr/>

    <div class="card border-stylish-color-dark my-5" [hidden]="capturaFileList?.length==0">
        <div class="card-header text-light">
            <div class="card-body text-app-color">
                <h5 class="card-title">Carga de información</h5>
                <hr/>
                <div>
                    <form class="form-inline my-5">
                        <div class="form-group">
                            <div class="container ">
                
                                <div class="input-group text-app-color">
                                    <h5 class="card-title">Filtrar información:</h5>
                                </div>
                                <div class="input-group text-app-color">                                    
                                    <label for="pnr" class="col-form-label">PNR</label>
                                    <div class="input-group-append col-xs-auto col-sm-auto col-md-5">
                                        <input type="text" class="form-control col-xs-auto col-sm-auto col-md-auto text-center" name="pnr"
                                            (change)="fieldsChange()" [(ngModel)]="filterPnr" #pnrfilter="ngModel">
                                    </div>
                
                                    <div class="input-group-append col-xs-auto col-sm-auto col-md-auto">
                                        <button class="btn btn-danger" role="button" (click)='filterList()'
                                            [disabled]="( filterPnr == '' )">Buscar</button>
                                    </div>
                                </div>
                
                            </div>
                        </div>
                    </form>
                </div>
                <hr/>
                <div class="col-auto">

                    <div class="float-right">
    
                        <div class="input-group-prepend float-right m-1">
                            Seleccionar todo &nbsp;
                            <div class="input-group-text center checkbox-inline">
                                <input type="checkbox" [(ngModel)]="selectedAllItems"
                                    (change)="selectAllItems()" />
                            </div>
                        </div>
                        <div class="input-group-prepend float-right m-1">
                            Seleccionar página &nbsp;
                            <div class="input-group-text center checkbox-inline">
                                <input type="checkbox" [(ngModel)]="selectedPage"
                                    (change)="selectPage(mdbTablePagination.firstItemIndex, mdbTablePagination.lastItemIndex); changeDisableSelected($event)" />
                            </div>
                        </div>
    
                    </div>

                    <table mdbTable #tableEl="mdbTable" class="table table-bordered table-striped table-hover table-sm table-responsive-lg">
                        <thead>
                            <tr>
                                <th class="text-center"><b>Channel</b></th>
                                <th class="text-center"><b>PSP</b></th>
                                <th class="text-center"><b>Account</b></th>
                                <th class="text-center"><b>Txn ID</b></th>
                                <th class="text-center"><b>Client ID</b></th>
                                <th class="text-center"><b>PNR</b></th>
                                <th class="text-center"><b>Point of sale</b></th>
                                <th class="text-center"><b>Pricing currency</b></th>
                                <th class="text-center"><b>Value</b></th>
                                <th class="text-center"><b>Seleccionar</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let record of capturaFileList; let i = index">
                                <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center"> {{record.channel}} </td>
                                <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center"> {{record.psp}} </td>
                                <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center"> {{record.account}} </td>
                                <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center"> {{record['Txn ID']}} </td>
                                <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center"> {{record['client-id']}} </td>
                                <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center"> {{record.pnr}} </td>
                                <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center"> {{record['Point of Sale']}} </td>
                                <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center"> {{record['Pricing Currency']}} </td>
                                <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center"> {{record.value}} </td>
                                <td *ngIf="i+1 >= mdbTablePagination.firstItemIndex && i < mdbTablePagination.lastItemIndex" scope="row" class="text-center">
                                    <div class="input-group-prepend justify-content-center">
                                        <div class="input-group-text center checkbox-inline">
                                            <input type="checkbox" id="selected_{{i+1}}"
                                                (change)="checkIfAllSelected();" [(ngModel)]="record.selected"
                                                aria-label="Checkbox for following text input">
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot class="grey lighten-5 w-100">
                            <tr>
                                <td colspan="10">
                                    <mdb-table-pagination #mdbTablePagination [tableEl]="tableEl" [searchDataSource]="capturaFileList"></mdb-table-pagination>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <hr/>
                <div class="input-group-append col-xs-auto col-sm-auto col-md-auto float-right">
                    <button class="btn btn-danger" role="button" [disabled]="checkIfaItemIsSelected()" (click)="sendCapture()">Realizar captura</button>
                </div>
            </div>
        </div>
    </div>

    <div class="card border-stylish-color-dark my-5" [hidden]="captureItemList?.length == 0">
        <div class="card-header text-light"></div>
        <div class="card-body text-app-color">
            <h5 class="card-title">Resultado</h5>
            <div class="col-auto">
                <table mdbTable #tableE2="mdbTable"
                    class="table table-bordered table-striped table-hover table-sm table-responsive-lg">
                    <thead>
                        <tr>
                            <th class="text-center"><b>PNR</b></th>
                            <th class="text-center"><b>Transaction ID</b></th>
                            <th class="text-center"><b>Mensaje</b></th>
                            <th class="text-center"><b>Status-code</b></th>
                            <th class="text-center"><b>Fecha de creación</b></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of captureItemList; let i = index">
                            <td *ngIf="i+1 >= mdbTablePaginationResult.firstItemIndex && i < mdbTablePaginationResult.lastItemIndex"
                                scope="row" class="text-center">{{item.pnr}}</td>
                            <td *ngIf="i+1 >= mdbTablePaginationResult.firstItemIndex && i < mdbTablePaginationResult.lastItemIndex"
                                scope="row" class="text-center">{{item['transaction-id']}}</td>
                            <td *ngIf="i+1 >= mdbTablePaginationResult.firstItemIndex && i < mdbTablePaginationResult.lastItemIndex"
                                scope="row" class="text-center">{{item.message}}</td>
                            <td *ngIf="i+1 >= mdbTablePaginationResult.firstItemIndex && i < mdbTablePaginationResult.lastItemIndex"
                                scope="row" class="text-center">{{item['status-code']}}</td>
                            <td *ngIf="i+1 >= mdbTablePaginationResult.firstItemIndex && i < mdbTablePaginationResult.lastItemIndex"
                                scope="row" class="text-center">{{item.created}}</td>                        </tr>
                    </tbody>
                    <tfoot class="grey lighten-5 w-100">
                        <tr>
                            <td colspan="5">
                                <mdb-table-pagination #mdbTablePaginationResult [tableEl]="tableE2"
                                    [searchDataSource]="captureItemList"></mdb-table-pagination>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>